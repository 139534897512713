import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"

const ErinHollandStoryPage = () => (

    <Layout pageClass="story-page">
    <SEO title="Erin Holland - Active+ - Story" />
    <main className="main">
        <div className="typography inner">
            <h1>Erin Holland - Active+</h1>          
            <p>I have been working with Dave for the past five months. Dave brings a wealth of business experience to the table and he has been invaluable for his insight, experience, and ability to investigate my business from an outside perspective. He has challenged me professionally and personally to set goals, decide on what really matters to me and how I can work to improve all aspects of my business.</p>

           <p>Even as an experienced business owner, I have found that having a mentor that can review all aspects of my business, from cashflow and systems to staff engagement and wellbeing, has really helped me to get the most out of my business in what has been a very challenging Covid period.</p>

            <p>Working with Dave I have been able to break my aspirations for the business down into concrete and attainable goals that give us real focus to work towards with our marketing.  He has helped me work on succession planning and how I want the business to run in the future. He has also kept me accountable with regards to my own aspirations and where my career will head in the next few years.</p>

            <p>Working with Dave has helped me maintain a positive focus and given me peace of mind that I fully understand my business, that we are contributing to the business in a positive way and that the business is in healthy shape both culturally and financially.</p>

            <p>I would highly recommend Dave and Experience on Tap to anyone looking for support, guidance and mentoring for their business.</p>
        </div>
    </main>
  </Layout>

)


export default ErinHollandStoryPage